button.ok {
    /* Layout Properties */
    padding: 9px 25px;
    /* UI Properties */
    background: #F4685E 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    border-radius: 4px;
    opacity: 1;
    border: 0px;
    text-align: center;
}

button.ok:hover {
    /* UI Properties */
    background: #E1493F 0% 0% no-repeat padding-box;
}

button.cancel {
    /* Layout Properties */
    padding: 9px 25px;
    /* UI Properties */
    background: #636363 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    border-radius: 4px;
    opacity: 1;
    border: 0px;
    text-align: center;
}

button.cancel:hover {
    /* UI Properties */
    background: #4B4B4B 0% 0% no-repeat padding-box;
}


/* target for tablet size */
@media (max-width: 1519px) {}

/* target for mobile size */
@media (max-width: 923px) {}