.create-report .form-list .item-full-content.vendor-ui-4 > .top-description {
    margin-bottom: calc(1.4rem - 5px);
}

.create-report .form-list .item-full-content.vendor-ui-4 > .bottom-description {
    margin-bottom: 24px;
}

.create-report .form-list .item-full-content.vendor-ui-4 button {
    /* UI Properties */
    background: var(--text-card-blue-4270e0) 0% 0% no-repeat padding-box;
    background: #4270E0 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}

.create-report .form-list .item-full-content.vendor-ui-4 input[type="text"]:disabled {
    color: #333333;
    -webkit-text-fill-color: #333333; /* 1. sets text fill to current `color` for safari */
    opacity: 1; /* 2. correct opacity on iOS */
}


.create-report .form-list .item-full-content.vendor-ui-4 .no-spinner::-webkit-outer-spin-button,
.create-report .form-list .item-full-content.vendor-ui-4 .no-spinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.create-report .form-list .item-full-content.vendor-ui-4 .no-spinner {
    -moz-appearance: textfield;
}

@media (max-width: 923px) {
    .create-report .form-list .item-full-content.vendor-ui-4 button {
        /* UI Properties */
        height: 4.5rem;
        width: 4.5rem;
        font-size: 24px;
    }
}