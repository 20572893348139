#maintenance {
}

#maintenance .page-title {
    margin-bottom: 3rem;
}

#maintenance .page-title .first {
    margin: 0px 1.9rem 0px 0px;
}

#maintenance .page-title .second {
    margin-bottom: 4px;
}

#maintenance .uncontrolled-tab {
    border: 0px;
}

#maintenance .uncontrolled-tab button {
    font-family: 'Noto Sans KR';
    font-size: 1.8rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.45px;
    text-align: center;
    color: #fff;

    padding: 1.5rem 6.6rem 1.4rem;
    border: 0px;
}

#maintenance .uncontrolled-tab li button {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

#maintenance .uncontrolled-tab li:first-child button {
    border-top-left-radius: 10px;
    border-top-right-radius: 0px;
}

#maintenance .uncontrolled-tab li:last-child button {
    border-top-left-radius: 0px;
    border-top-right-radius: 10px;
}

#maintenance .nav-tabs .nav-link:not(.active) {
    background-color: #a3aabc;
    margin-bottom: 0px;
}

#maintenance .maint-modals .item-row,
#maintenance .maint-events .item-row,
#maintenance .maint-widgets .item-row {
    border-bottom: 1px solid #ccc;
}

#maintenance .maint-modals .item-row:first-child,
#maintenance .maint-widgets .item-row:first-child,
#maintenance .maint-events .item-row:first-child {
    background-color: #f7f7f7;
    border-top: 3px solid #000;
}

#maintenance .maint-events .item-row .item.first {
    padding: 2rem 0;
    width: 100%;
    max-width: 29rem;
    text-align: center;
}

#maintenance .maint-events .item-row .item.second {
    padding: 2rem 0;
    width: 100%;
    /* max-width: 250px; */
    text-align: center;
}

#maintenance .maint-events .item-row .item.third {
    padding: 2rem 0;
    width: 100%;
    max-width: 26rem;
    text-align: center;
}

#maintenance .maint-events .item-row .item.fourth {
    padding: 2rem 0;
    width: 100%;
    max-width: 22rem;
    text-align: center;
}

#maintenance .maint-events .item-row .item.fourth button{
    border: 0px;
    padding: 0 1rem;
    height: 3.8rem;
    border-radius: 4px;
    background-color: #546fa6;
}

#maintenance .maint-events .item-row .item.fourth img {
    width: 1.7rem;
    height: 1.7rem;
    margin-left: 1.3rem;
    /* object-fit: contain; */
}

#maintenance .maint-events .reviewing-state {
    max-width: 9.5rem;
    width: 100%;
    height: 3.8rem;
    padding: 0;
    border-radius: 4px;
    background-color: #87ac2f;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

#maintenance .histories .acquired-state {
    max-width: 9.5rem;
    width: 100%;
    height: 3.8rem;
    padding: 0;
    border-radius: 4px;
    background-color: #4270e0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

#maintenance .maint-events .rejected-state {
    border: 0px;
    max-width: 9.5rem;
    width: 100%;
    height: 3.8rem;
    padding: 0;
    border-radius: 4px;
    background-color: #636363;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

#maintenance .register-ai-model .request-form-title {
    padding: 3.3rem 0 2.3rem 0;
    border-top: 1px solid #4270e0;
    border-bottom: 3px solid #000;
}

#maintenance .register-ai-model  .item-row {
    border-bottom: 1px solid #dadada;
}

#maintenance .register-ai-model .item-title {
    max-width: 17.5rem;
    width: 100%;
    padding: 0 4.6rem 0 1.7rem;
    background-color: #f7f7f7;
}

#maintenance .register-ai-model .confirm-btn-group {
    padding: 6rem 0 0 0;
}

#maintenance .register-ai-model .save-btn {
    /* width: 140px;
    height: 55px; */
    margin: 0px;
    padding: 1.5rem 7.9rem 1.3rem 2.8rem;
    border-radius: 4px;
    background-color: #4270e0;
    border: 0px;

    background-image: url("/public/component-168-2@3x.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: 85% center;
}

#maintenance .register-ai-model .item {
    margin: 1.2rem 1.4rem 1.2rem 1.4rem;
}

#maintenance .register-ai-model .item input,
#maintenance .register-ai-model .item textarea {
    padding: 1.3rem 2.1rem 1.2rem 2.1rem;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    max-height: 4.5rem;
}

#maintenance .register-ai-model .item textarea {
    max-height: unset;
}

#maintenance .upload-zone #create-group-representative-picture-drop {
    display: block !important;
}

#maintenance .upload-zone #create-group-representative-picture-drop div.drag-n-drop div,
#maintenance .upload-zone #create-group-representative-picture-drop div.drag-n-dropping {
    height: 15.1rem;
    width: 15.1rem;

    cursor: pointer;

    margin: 0 1.2rem 0 0;

    border-radius: 4px;
    border: solid 1px #c8cdd5;
    background-color: #ebeef4;

    background-image:url('/public/component-219-1@3x.png');
    background-size: 46px;
    background-repeat: no-repeat;
    background-position: center;
}

#maintenance .upload-images div.each-image {
    margin: 0 1.2rem 0 0;

    border: solid 1px #c8cdd5;
    border-radius: 4px;

    height: 15.1rem;
    width: 15.1rem;
}

#maintenance .upload-images .upload-image-wrap {
    position:relative;

    width: 100%;
    height: 100%; 
}


#maintenance .upload-images li {
    margin: 0 0px 0 12px;
    border: solid 1px #c8cdd5;
    border-radius: 4px;

    height: 15.1rem;
    width: 15.1rem;
}

#maintenance .upload-images .upload-image-wrap img {
    padding: 0px;
    object-fit: cover;
    border-radius: 4px;

    width: 100%;
    height: 100%; 
}


#maintenance  .upload-images .upload-image-wrap button.remove-picture {
    border: 0px;
    position: absolute;
    margin: 13px 10px; 
    right: 0px;
    width: 30px;
    height: 30px;
    padding: 6px;

    background-color: transparent;
    background-image:url('/public/group18505@3x.png');
    background-size: 23px;
    background-repeat: no-repeat;
    background-position: center;

    filter: drop-shadow(0 2px 1px #1a1a1a);
}

#maintenance  .upload-images .upload-image-wrap button.representative {
    border: 0px;
    position: absolute;
    margin: 1.3rem 1rem; 

    padding: 0.7rem 0.9rem 0.6rem 1rem;
    border-radius: 4px;
    background-color: #000;
}

#maintenance .register-ai-model .project-duration input:first-child {
    letter-spacing: -1.05px;
    padding-right: 1.7rem;
    margin-right: 0px;
}

#maintenance .register-ai-model .project-duration input:last-child {
    letter-spacing: -1px;
    padding-right: 1.7rem;
    margin-left: 0px;
}

#maintenance .register-ai-model .item input[type="date"] {
    letter-spacing: -1.05px;
    max-width: 15.1rem;
    width: 100%;
    padding-right: 1.1rem;
}

#maintenance .register-ai-model .project-duration input ::-webkit-calendar-picker-indicator {
    margin-left: 2rem;
}

#maintenance .register-ai-model .item .caution {
    line-height: 1.43;
    letter-spacing: -0.7px;
}

#maintenance .register-ai-model .create-group-files {
    width: 100%;
    max-height: 4.5rem;
    line-height: 1.36;
    cursor: default;

    padding: 1.3rem 2rem 1.2rem 2.1rem;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

#maintenance .register-ai-model .file-group {
    margin: 1rem 0.6rem 1.2rem 0;
}

#maintenance .register-ai-model .item button.remove-file {
    border: 0px;
    padding: 0px;
    background-color: transparent;
}

#maintenance .register-ai-model .item .files-wrap:last-child {
}


#maintenance .register-ai-model .item button.remove-file img {
    width: 18px;
    height: 18px;
}

#maintenance .register-ai-model .item button {
    /* margin: 12px 567px 11px 6px; */
    max-height: 4.5rem;
    margin-left: 0.6rem;
    padding: 1.2rem 1.5rem 1.1rem;
    border-radius: 4px;
    background-color: #2a2f39;
    border: 0px;
}


#maintenance .register-ai-model .item.permission {
    margin: 3.1rem 0px 3.1rem 1.4rem;
}


#maintenance .item-row .item a {
    text-decoration: underline dotted #4270e0;
    text-underline-offset: 5px;
}

#maintenance .register-ai-model .applicant {
    display: flex;
}

#maintenance #create-group-username,
#maintenance #create-group-contact {
    max-width: 25rem;
}

#maintenance .execute {
    max-width: 92px;
    min-width: 80%;
    height: 34px;
    border-radius: 4px;
    background-color: #f4685e;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

@media (max-width: 923px) {
    #maintenance .page-title .first {
        margin: 0px 6px 0px 0px;
    }

    #maintenance .uncontrolled-tab li:first-child button {
        border-top-left-radius: 4px;
        border-top-right-radius: 0px;
    }

    #maintenance .uncontrolled-tab li:last-child button {
        border-top-left-radius: 0px;
        border-top-right-radius: 4px;
    }

    #maintenance .register-ai-model .request-form-title {
        border-top: 0;
    }

    #maintenance .uncontrolled-tab button {
        font-size: 15px;
        height: 41px;
        padding: 0;
        border: 0px;
        width: 100%;
    }

    #maintenance .uncontrolled-tab .nav-item {
        width: 50%;
    }

    #maintenance .tab-content {
        margin-top: 10px;
    }

    #maintenance .register-ai-model .item-title {
        background-color: unset;
        padding: 0;
    }

    #maintenance .register-ai-model .item {
        margin: 5px 0 10px 0;
    }

    #maintenance .register-ai-model .item-row:first-child {
        margin-top: 28px;
    }

    #maintenance .register-ai-model .item-row {
        margin-bottom: 20px;
    }

    #maintenance .register-ai-model .applicant {
        display: unset;
    }

    #maintenance .register-ai-model .applicant:last-child {
        border-top: 1px solid #dadada;
        padding-top: 20px;
    }

    #maintenance .register-ai-model .item .caution {
        margin: 0;
        line-height: 1.43;
        letter-spacing: -0.7px;
    }

    #maintenance .register-ai-model .item .file-group #create-group-files button {
        margin-left: 0;
        width: 100%;
        margin-top: 8px;
    }

    #maintenance .register-ai-model .file-group,
    #maintenance .register-ai-model #create-group-files {
        display: unset !important;
        max-height: 4.5rem;
        margin: 0;
    }

    #maintenance .register-ai-model .create-group-files {
        display: none;
    }

    #maintenance .register-ai-model .item input,
    #maintenance .register-ai-model .item textarea {
        width: 100% !important;
    }

    #maintenance .register-ai-model .confirm-btn-group {
        padding: 2rem 0 0 0;
    }

    #maintenance .register-ai-model .save-btn {
        width: 100%;
        padding: 1.4rem 0;
    }

    #maintenance #create-group-username,
    #maintenance #create-group-contact {
        max-width: unset;
    }

    #maintenance .register-ai-model .item.permission {
        margin: 5px 0 10px 0;
    }
}
